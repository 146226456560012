<!-- Two amazing illustration sites but they are not icons :(
https://www.openpeeps.com/
https://absurd.design/ -->

<script>
	import Header from "./components/Header.svelte";
	import Cards from "./components/Cards.svelte";
</script>

<main>
	<Header />
	<Cards />
</main>

<style>
	:root {
		background-color: var(--alt-bg);
		margin: 1rem;
	}
</style>