<section>
    <h1>The Icons Website</h1>
    <h2>A curated list of high quality icon repositories for any occasion.</h2>
</section>

<style>
    section {
        padding-bottom: 4rem;
        max-width: 40rem;
        margin: auto;
    }
    h1, h2 {
        color: white;
        text-align: center;
    }
    h1 {
        font-size: 4rem;
        font-weight: 200;
    }
    h2 {
        font-style: italic;
        font-size: 1.5rem;
    }
</style>